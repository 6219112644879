import { XCircleIcon } from '@heroicons/react/24/outline'
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage'
import React, { useEffect, useState } from 'react'
import Loading from '../../components/loading/loading'
import Prompt from '../../components/prompt/prompt'
import { Company } from '../../contracts/company'
import { storage } from '../../infrastructure/firebase'
import { listCompanies } from '../../services/company'

const getImageFilename = (url: string) => {
  const uri = new URL(url)
  const pathname = uri.pathname.substring(`/v0/b/discounts-93354.appspot.com/o/images%2Fcompanies%2F`.length)
  return pathname
}

export const Head = () => {
  return (
    <title>Upload Images - Disco Deals</title>
  )
}

export default () => {
  const [isUploading, setIsUploading] = useState(false)
  const [companyImages, setCompanyImages] = useState<Record<string, string>>()
  const [companyImageToDelete, setCompanyImageToDelete] = useState<string>()
  const [companies, setCompanies] = useState<Company[]>()

  useEffect(() => {
    const fetch = async () => {
      await Promise.all([
        fetchImages(),
        fetchCompanies()
      ])
    }
    fetch()
  }, [])

  const fetchCompanies = async () => {
    setCompanies(await listCompanies())
  }
  
  const fetchImages = async () => {
    const imagesRef = ref(storage(), `images/companies`)
    const images = await listAll(imagesRef)

    const results = await Promise.all(images.items.map(async ref => {
      const filename = ref.fullPath.split('/').reverse()[0] // eg, "t.k. maxx.jpg"
      const fileExtIndex = filename.lastIndexOf('.')
      const companyId = filename.substring(0, fileExtIndex)
      const imageUrl = await getDownloadURL(ref)
      return { companyId, imageUrl }
    }))

    const imageLookup = results.reduce((acc, curr) => {
      acc[curr.companyId] = curr.imageUrl
      return acc
    }, {} as Record<string, string>)
    
    setCompanyImages(imageLookup)
  }
  
  const uploadToStorage = async (companyId: string, file: File) => {
    setIsUploading(true)
    const imageRef = ref(storage(), `images/companies/${companyId}.jpg`)
    await uploadBytes(imageRef, file)
    console.log('uploaded')
    setIsUploading(false)
    setCompanyImages(undefined)
    await fetchImages()
  }

  const handleOnDragOver: React.DragEventHandler<HTMLDivElement> = e => {
    e.preventDefault()
  }

  const handleOnDrop = (e: React.DragEvent<HTMLDivElement>, companyId: string) => {
    e.preventDefault()
    const file = e.dataTransfer.files[0]
    if (file) {
      uploadToStorage(companyId, file)
    }
  }

  const handleDelete = async (companyId: string) => {
    setCompanyImageToDelete(companyId)
  }

  const doDelete = async () => {
    const url = companyImages![companyImageToDelete!]
    const filename = getImageFilename(url!)

    delete companyImages![companyImageToDelete!]
    setCompanyImageToDelete(undefined)
    const imageRef = ref(storage(), `images/companies/${filename}`) 
    await deleteObject(imageRef)
    console.log('deleted', `images/companies/${filename}`)
  }
  
  return (
    <main className="pt-4 container">
      {companyImageToDelete && <Prompt
        title='Are you sure you want to delete this image?'
        confirmText='Delete'
        onConfim={doDelete}
        onCancel={() => setCompanyImageToDelete(undefined)}
      />}
      {isUploading && <div
        className="fixed bg-gray-200 bg-opacity-90 h-full w-full left-0 top-0 flex justify-center items-center"
      >
        <span className="text-xl">
          <Loading label='Uploading...'/>
        </span>
      </div>}

      <div className="rounded-md bg-blue-50 p-4 mb-4">
        <div className="flex">
          <div className="ml-3 flex-1 md:flex md:justify-between">
            <p className="text-sm text-blue-700">        Drop logo images onto the companies below to upload.</p>
          </div>
        </div>
      </div>

      <section>
        <h2 className="section-title">Companies</h2>

        {(!companies || !companyImages) && <Loading label="Loading companies..." />}

        {(companies && companyImages) &&
          companies.map(company => {
            const imageUrl: string | undefined = companyImages[company.id]
            return (
              <div
                key={company.id}
                onDrop={e => handleOnDrop(e, company.id)}
                onDragOver={handleOnDragOver}
                className="border border-collapse p-8 hover:border-primary-600"
              >
                {company.name}
                {imageUrl && <>
                    <img src={imageUrl} className="border flex-shrink h-16" />
                    <span>
                      {getImageFilename(imageUrl)}
                      <button className="w-6 text-red-700" title="Delete image"><XCircleIcon onClick={() => handleDelete(company.id)} /></button>
                    </span>
                  </>
                }
              </div>
            )
          })
        }
      </section>
    </main>
  )
}